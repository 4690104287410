import React from "react";
import Layout from "../components/Layout";

const Service_one = () => {
    return (
        <Layout>
            <div id="main">
                <div className="services_section_banner">
                    <div className="services_banner"
                         style={{backgroundImage: `url(../images/asesoria_y_consultoria_juridico%20_laboral.jpg)`}}>
                        <h1>Asesoría y Consultoría Jurídico Laboral</h1>
                    </div>
                </div>
                <div className="inner">
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Descripción</h1>
                        <p className="services_section_1__paragraph">
                            Desde la realización de auditorías jurídica laboral hasta el desarrollo de recomendaciones
                            legales
                        </p>
                    </div>
                    <div className="card">
                        <div className="card__content">
                            <p style={{color: '#000'}}>Nuestro equipo de Asesores Legales, cuenta con más de 20 años de
                                experiencia ofreciendo un servicio personalizado y de máxima calidad. Aportamos siempre
                                las soluciones jurídicas más eficientes y el asesoramiento legal requerido, con el único
                                fin de alcanzar los máximos niveles de satisfacción de nuestros clientes.</p>
                            <ul style={{color: '#000000'}}>
                                <li>Negociación Colectiva.</li>
                                <li>Servicios de Conciliación y Arbitraje.</li>
                                <li>Reestructuraciones Empresariales.</li>
                                <li>Convenios de Empresa.</li>
                                <li>Diseño Políticas Retributivas.</li>
                                <li>Procedimientos Judiciales.</li>
                                <li>Despidos y Resoluciones Contractuales.</li>
                                <li>Aplicación del Régimen Sancionador.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Service_one
